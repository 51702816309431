import moment, { Moment } from 'moment-timezone';
import get from 'lodash/get';
import Act from '../activity/act/model/Act';

class NAM {
  constructor(
    /* eslint-disable no-unused-vars */
    public readonly code: string,
    public readonly birth: Moment
  ) {}

  public static fromAct(act: Act): NAM {
    return NAM.fromV1(act.nam!, get(act, 'patientInformation.shiftNamCentury', false));
  }

  public static fromV1(nam: string, isCentenary: boolean = false): NAM {
    if (!NAM.isValidForV1(nam)) {
      throw new Error('Invalid NAM');
    }

    const day = nam.substring(8, 10).padStart(2, '0');
    const month = (+nam.substring(6, 8) % 50).toString().padStart(2, '0');
    const year = nam.substring(4, 6).padStart(2, '0');

    const birth = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');

    if (isCentenary || birth.isAfter(moment())) {
      birth.subtract(100, 'years');
    }

    return new NAM(nam, birth);
  }

  public static isValidForV1(nam: string): boolean {
    return /[A-Z]{4}\d{8,12}/.test(nam);
  }
}

export default NAM;
