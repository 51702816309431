import React, { useMemo, useState } from 'react';
import useTranslate from 'application/hooks/use-translate';
import Dropdown from 'application/components/dropdown';
import StatementReport from 'application/pages/groups/group/components/reports/statement-report';
import PaymentRequestsBySentDateReport from 'application/pages/groups/group/components/reports/payment-requests-by-sent-date-report';
import PaymentRequestsByCategoryReport from 'application/pages/groups/group/components/reports/payment-requests-by-category-report';
import PaymentRequestsByCodeTypeReport from 'application/pages/groups/group/components/reports/payment-requests-by-code-type-report';
import OutsideRAMQReport from 'application/pages/groups/group/components/reports/outside-ramq-report';
import AnnualGuardByDateOfServiceReport from 'application/pages/groups/group/components/reports/annual-guard-by-date-of-service-report';
import PeriodSummaryReport from 'application/pages/groups/group/components/reports/period-summary-report';
import ReceivableAccountsReport from 'application/pages/groups/group/components/reports/receivable-accounts-report';
import GuardValueBySentDateReport from 'application/pages/groups/group/components/reports/guard-value-by-sent-date-report';
import { Navigate, useParams } from 'react-router-dom';

enum Report {
  RECEIVABLE_ACCOUNTS = 'receivable-accounts',
  PAYMENT_REQUESTS_BY_CATEGORY = 'payment-requests-by-category',
  PAYMENT_REQUESTS_BY_SENT_DATE = 'payment-requests-by-sent-date',
  PAYMENT_REQUESTS_BY_CODE_TYPE = 'payment-requests-by-code-type',
  STATEMENT = 'statement',
  ANNUAL_GUARD_BY_DATE_OF_SERVICE = 'annual-guard-by-date-of-service',
  GUARD_BY_SENT_DATE = 'guard-by-sent-date',
  OUTSIDE_RAMQ = 'outside-ramq',
  PERIOD_SUMMARY = 'period-summary'
}

const GroupReportsPage = () => {
  const { groupId } = useParams();
  const translate = useTranslate('reports');
  const [selectedReport, setSelectedReport] = useState<string>(Report.STATEMENT);

  const SelectedReport = useMemo(() => {
    if (selectedReport === Report.STATEMENT) return StatementReport;
    if (selectedReport === Report.PERIOD_SUMMARY) return PeriodSummaryReport;
    if (selectedReport === Report.PAYMENT_REQUESTS_BY_SENT_DATE) return PaymentRequestsBySentDateReport;
    if (selectedReport === Report.PAYMENT_REQUESTS_BY_CATEGORY) return PaymentRequestsByCategoryReport;
    if (selectedReport === Report.PAYMENT_REQUESTS_BY_CODE_TYPE) return PaymentRequestsByCodeTypeReport;
    if (selectedReport === Report.OUTSIDE_RAMQ) return OutsideRAMQReport;
    if (selectedReport === Report.ANNUAL_GUARD_BY_DATE_OF_SERVICE) return AnnualGuardByDateOfServiceReport;
    if (selectedReport === Report.RECEIVABLE_ACCOUNTS) return ReceivableAccountsReport;
    if (selectedReport === Report.GUARD_BY_SENT_DATE) return GuardValueBySentDateReport;

    throw new Error(`Report ${selectedReport} not supported`);
  }, [selectedReport]);

  const reportChoices = Object.values(Report);

  if (!groupId) return <Navigate to="/" />;

  return (
    <div className="relative rounded-md bg-base-100 shadow">
      <div className="flex flex-row items-center justify-between border-b-2 p-4">
        <Dropdown
          onChange={setSelectedReport}
          options={reportChoices.map((value) => ({ label: translate(value), value }))}
          value={selectedReport}
        />
      </div>

      {SelectedReport && <SelectedReport groupId={groupId} />}
    </div>
  );
};

export default GroupReportsPage;
