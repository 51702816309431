import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { getRamqNumberOfHoursWorkedFromTimestamps } from '../../../../ramq/ramqCommonUtils';

const { range } = extendMoment(moment as any);

const CAPQ_CODE_DEFINITIONS = new Map([
  ['078122', { maximumDurationPerTimeSlot: null }],
  ['078123', { maximumDurationPerTimeSlot: null }],
  ['078124', { maximumDurationPerTimeSlot: 12 }],
  ['078129', { maximumDurationPerTimeSlot: 12 }],
  ['078300', { maximumDurationPerTimeSlot: 8 }],
  ['078309', { maximumDurationPerTimeSlot: 8 }]
]);

export const CAPQ_CODES = Array.from(CAPQ_CODE_DEFINITIONS.keys());

interface Day {
  activityArea: string;
  code: string;
  date: number;
  end: number;
  start: number;
}

enum PartOfDay {
  AM = 'am',
  EVENING = 'soir',
  NIGHT = 'nuit',
  PM = 'pm'
}

interface TimeSlot {
  date: number;
  end: number;
  start: number;
  timeslot: PartOfDay;
}

interface Boundaries {
  lower: number;
  upper: number;
}

const CAPQ_TIME_SLOTS: Array<[PartOfDay, Boundaries]> = [
  [PartOfDay.NIGHT, { lower: 0, upper: 8 }],
  [PartOfDay.AM, { lower: 8, upper: 12 }],
  [PartOfDay.PM, { lower: 12, upper: 16 }],
  [PartOfDay.EVENING, { lower: 16, upper: 24 }],
  [PartOfDay.NIGHT, { lower: 24, upper: 34 }]
];

class CAPQTimeSlotCalculator {
  public calculate(day: Day) {
    return this.computeTimeSlots(day).map(({ date, end, start, timeslot }) => ({
      details: [
        {
          code: day.code,
          hoursWorked: getRamqNumberOfHoursWorkedFromTimestamps(start, end),
          sector: day.activityArea
        }
      ],
      timePeriod: { date, end, start, timeslot },
      day: moment(date).format('DD'),
      date: moment(date).format('YYYYMMDD')
    }));
  }

  private computeTimeSlots({ code, date, start, end }: Day): TimeSlot[] {
    const timeslots: TimeSlot[] = [];

    const dayStart = moment(start);
    const dayEnd = moment(end);
    const codeDefinition = CAPQ_CODE_DEFINITIONS.get(code);

    if (!codeDefinition) return timeslots;

    if (dayEnd.isSameOrBefore(dayStart)) {
      dayEnd.add(1, 'day');
    }

    const cursor = dayStart.clone();

    while (cursor.isBefore(dayEnd)) {
      const timeslot = CAPQ_TIME_SLOTS.find(([_key, { lower, upper }]) => {
        const slotLowerBoundDate = moment(cursor).hour(lower);
        const slotUpperBoundDate = moment(cursor).hour(upper);
        const slotRange = range(slotLowerBoundDate, slotUpperBoundDate);

        if (!slotRange.contains(cursor, { excludeEnd: true })) return false;

        return true;
      });

      if (!timeslot) return timeslots;

      const [partOfDay, boundaries] = timeslot;

      const slotDuration = codeDefinition.maximumDurationPerTimeSlot
        ? codeDefinition.maximumDurationPerTimeSlot
        : moment.min([dayEnd, moment(cursor).hour(boundaries.upper)]).diff(cursor, 'hours');

      timeslots.push({
        date,
        start: cursor.valueOf(),
        end: cursor.add(slotDuration, 'hours').valueOf(),
        timeslot: partOfDay
      });
    }

    return timeslots;
  }
}

export default CAPQTimeSlotCalculator;
