import Dialog from '@material-ui/core/Dialog';
import withStyles from '@material-ui/core/styles/withStyles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import find from 'lodash/find';
import DialogCenteredTitle from '../../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import SwipeableTabs from '../../../components/SwipeableTabs/SwipeableTabs';
import messages from '../../../containers/CodeSearchDialog/messages';
import isSameCodeItem from '../utils/isSameCodeItem';
import SearchActCodesTab from './SearchActCodesTab/SearchActCodesTab';
import FavoritesActCodesTabFactory from './FavoritesActCodesTabFactory/FavoritesActCodesTabFactory';
import ActCodePresetsTab from './ActCodePresetsTab/ActCodePresetsTab';
import { getActCodesDataWithSpecialtyOverrides } from '../../../../shared/data/actCodesData';

export const styles = (theme) => ({
  paper: {
    minHeight: '90vh',
    height: '100%'
  },
  slideChildContainer: {
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'paddingBottom': 0,
    'paddingTop': theme.spacing(0.5),
    '&:first-child': {
      paddingTop: theme.spacing(0.5)
    }
  }
});

export class ActCodesSearchDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCodes: []
    };

    this.codeToggled = this.codeToggled.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.open && prevState.selectedCodes.length > 0) {
      return { selectedCodes: [] };
    }

    return prevState;
  }

  codeToggled(code, callback = () => {}) {
    this.setState((previousState) => {
      const { selectedCodes } = previousState;
      const indexOfCurrentCodeIntoSelectedList = selectedCodes.findIndex((selectedItem) =>
        isSameCodeItem(selectedItem, code)
      );
      const currentCodeIsAlreadySelected = indexOfCurrentCodeIntoSelectedList !== -1;
      if (currentCodeIsAlreadySelected) {
        const updatedSelectedCodes = [...selectedCodes];
        updatedSelectedCodes.splice(indexOfCurrentCodeIntoSelectedList, 1);

        return {
          ...previousState,
          selectedCodes: updatedSelectedCodes
        };
      }
      return {
        ...previousState,
        selectedCodes: [...selectedCodes, code]
      };
    }, callback);
  }

  fetchLatestCodeVersion(code) {
    return find(getActCodesDataWithSpecialtyOverrides(), (actCode) => actCode.code === code.code);
  }

  handleConfirm() {
    const finalCodeVersion = this.state.selectedCodes.map(this.fetchLatestCodeVersion);
    this.props.onConfirm(finalCodeVersion);
  }

  render() {
    const { fullScreen, open, onClose, classes } = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={open}
        onClose={this.handleClose}
        classes={{ paper: classes.paper }}
      >
        <DialogCenteredTitle label={<FormattedMessage {...messages.codeSearchTitle} />} />
        <SwipeableTabs
          swipeableViewsProps={{
            containerStyle: { height: '100%' },
            slideStyle: { overflow: 'hidden' }
          }}
          tabs={[
            {
              key: 'favorites',
              icon: <FavoriteIcon />,
              component: (
                <div className={classes.slideChildContainer}>
                  <FavoritesActCodesTabFactory
                    selectedCodes={this.state.selectedCodes}
                    onClickItem={this.codeToggled}
                    onConfirm={this.handleConfirm}
                    onCancel={onClose}
                  />
                </div>
              )
            },
            {
              key: 'presets',
              icon: <FolderSpecialIcon />,
              component: (
                <div className={classes.slideChildContainer} key="presets-tab">
                  <ActCodePresetsTab
                    selectedCodes={this.state.selectedCodes}
                    onClickItem={this.codeToggled}
                    onConfirm={this.handleConfirm}
                    onCancel={onClose}
                  />
                </div>
              )
            },
            {
              key: 'search',
              icon: <SearchIcon />,
              component: (
                <div className={classes.slideChildContainer}>
                  <SearchActCodesTab
                    onSuggestionClick={(code) => this.codeToggled(code, this.handleConfirm)}
                    onClose={onClose}
                  />
                </div>
              )
            }
          ]}
        />
      </Dialog>
    );
  }
}

ActCodesSearchDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default compose(withMobileDialog(), withStyles(styles))(ActCodesSearchDialog);
