/* eslint-disable react/prop-types */
import moment from 'moment-timezone';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CancelIcon from '@material-ui/icons/Cancel';
import PrevArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import React from 'react';
import { intersection } from 'lodash';
import { Field } from 'redux-form';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import { formatDateStringToUnix, formatUnixToDate } from '../../../shared/utils/dateTime/dateTimeUtils';
import PatientEventSelector from '../../containers/ActForm/PatientEventSelector/PatientEventSelector';
import PlaceSelector from '../../containers/ActForm/PlaceSelector/PlaceSelector';
import PatientEventsDialog from '../../containers/PatientEventsDialog/PatientEventsDialog';
import PlacesDialog from '../../containers/PlacesPreferencesDialog/PlacesDialog';
import InputDialogTrigger from '../Dialogs/DialogTrigger/InputDialogTrigger/InputDialogTrigger';
import MeasurementElements from './ActSpecificField/MeasurementElements/MeasurementElements';
import MultiTime from './ActSpecificField/MultiTime/MultiTime';
import BasicField from './BasicField';
import SingleCheckbox from './Checkbox/SingleCheckbox/SingleCheckbox';
import SingleSelectCheckboxGroup from './Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { PROFESSIONAL_TYPE_LIST, UNIVERSITY_LIST } from './constants';
import DeletableValueOnUnmountField from './custom/DeletableValueOnUnmountField';
import PhoneMask from './custom/PhoneMask';
import ListSelector from './ListSelector/ListSelector';
import NamInput from './NamInput/NamInput';
import MultiDiagnostic from './ActSpecificField/Diagnostics/MultiDiagnostic';
import { displayCode, displayDoctor, displayPatientEvent, displayPlace } from './utils/fieldTransformations';
import DoctorsDialogFactory from '../../doctorsRegistry/ui/containers/DoctorsDialogFactory/DoctorsDialogFactory';
import DiagnosticCodesSearchDialog from '../../favorite/diagnosticCode/ui/DiagnosticCodesSearchDialog/DiagnosticCodeSearchDialog';
import { selectSpecialtyDiagnosticCodesFromUser } from '../../favorite/diagnosticCode/adapters/selectors';
import { getStore } from '../../reduxStore/configureStore';
import { getFlattenItemsFromCategory } from '../../../utils/listUtils';
import DoctorAutocomplete from '../../containers/ActForm/DoctorAutocomplete/DoctorAutocomplete';
import PlaceAutocomplete from '../../containers/ActForm/PlaceAutocomplete/PlaceAutocomplete';
import { useSelector } from 'react-redux';
import { selectCurrentActForm } from 'app/containers/ActForm/selectors';
import { usePostHog } from 'posthog-js/react';

export function IntensiveCareDiagnosticSelectField(props) {
  return (
    <InputDialogTrigger
      {...props}
      formatValueFunction={displayCode}
      DialogComponent={DiagnosticCodesSearchDialog}
      dialogProps={{
        diagnosticList: selectSpecialtyDiagnosticCodesFromUser()(getStore().getState()),
        initiallyOpen: false
      }}
    />
  );
}

export function MultiDiagnosticSelectField({ input, meta, label }) {
  let selectedValues = [];

  if (input.value.length > 0) {
    selectedValues = input.value.map(({ code }) => code);
  }

  const diagnosticList = selectSpecialtyDiagnosticCodesFromUser()(getStore().getState()).filter((category) => {
    const items = getFlattenItemsFromCategory(category);

    return (
      intersection(
        items.map(({ code }) => code),
        selectedValues
      ).length === 0
    );
  });

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <MultiDiagnostic dialogProps={{ diagnosticList, initiallyOpen: false }} input={input} />
      {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
    </>
  );
}

export function DoctorSelectField(props) {
  return <InputDialogTrigger {...props} formatValueFunction={displayDoctor} DialogComponent={DoctorsDialogFactory} />;
}

export function DoctorAutocompleteField(props) {
  const { meta } = props;
  return (
    <>
      <DoctorAutocomplete {...props} />
      {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
    </>
  );
}

export function PatientEventSelectField(props) {
  return (
    <PatientEventSelector {...props} formatValueFunction={displayPatientEvent} DialogComponent={PatientEventsDialog} />
  );
}

export function PlaceSelectField(props) {
  return <PlaceSelector {...props} formatValueFunction={displayPlace} DialogComponent={PlacesDialog} />;
}

export function PlaceAutocompleteField(props) {
  const { meta } = props;
  return (
    <>
      <PlaceAutocomplete {...props} />
      {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
    </>
  );
}

export function CheckboxField({ input, label, ...props }) {
  return (
    <BasicField label="" {...props}>
      <SingleCheckbox input={input} label={label} />
    </BasicField>
  );
}

export function MultiTimeField({ input, meta, label, ...rest }) {
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <MultiTime input={input} {...rest} />
      {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
    </>
  );
}

export function DateField({ input, deletable, posthogEvent = undefined, ...props }) {
  const posthog = usePostHog();

  const handleOnChange = (value) => {
    if (posthogEvent) {
      posthog.capture(posthogEvent, { from: new Date(input.value), to: value ? value.toDate() : null });
    }

    input.onChange(formatDateStringToUnix(value));
  };

  return (
    <div style={{ display: 'flex' }}>
      <BasicField
        {...props}
        InputLabelProps={{
          shrink: true
        }}
      >
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <DatePicker
            type="text"
            disabled={props.disabled}
            style={{ marginTop: '18px' }}
            value={input.value ? formatUnixToDate(input.value) : null}
            onChange={handleOnChange}
            format="YYYY-MM-DD"
            leftArrowIcon={<PrevArrowIcon />}
            rightArrowIcon={<NextArrowIcon />}
          />
        </MuiPickersUtilsProvider>
      </BasicField>
      {deletable && input.value !== '' ? (
        <IconButton aria-label="Delete" onClick={() => input.onChange('')}>
          <CancelIcon />
        </IconButton>
      ) : null}
    </div>
  );
}

export function NoteField(props) {
  return <BasicField {...props} multiline maxRows="10" />;
}

export function CommentField(props) {
  return <BasicField {...props} multiline disableUnderline />;
}

export function NumberField(props) {
  return <BasicField {...props} inputProps={{ pattern: '[0-9]*', type: 'number' }} />;
}

export function SingleLineField(props) {
  return <BasicField {...props} />;
}

export function NamField({ input, posthogCaptureManualEntry, ...rest }) {
  const posthog = usePostHog();
  const { id: actId } = useSelector(selectCurrentActForm());

  const handleNamFull = (_value) => {
    if (posthogCaptureManualEntry) {
      posthog.capture('NAM manually entered', { actId });
    }
  };

  return (
    <BasicField
      {...rest}
      InputLabelProps={{
        shrink: true
      }}
    >
      <NamInput selectedNam={input.value} onChange={(value) => input.onChange(value)} onNamFull={handleNamFull} />
    </BasicField>
  );
}

export function PhoneNumberField(props) {
  return (
    <BasicField
      {...props}
      InputLabelProps={{
        shrink: true
      }}
      inputComponent={PhoneMask}
    />
  );
}

export function ProfessionalTypeSelectorField(props) {
  return <SelectorField {...props} list={PROFESSIONAL_TYPE_LIST} />;
}

export function UniversitySelectorField(props) {
  return <SelectorField {...props} list={UNIVERSITY_LIST} />;
}

export function SelectorField({ input, list, ...rest }) {
  return (
    <BasicField {...rest}>
      <ListSelector values={list} input={input} />
    </BasicField>
  );
}

export function AdminRestrictedNumberField(props) {
  return (
    <Restrict atLeastRole={Role.Admin}>
      <NumberField {...props} />
    </Restrict>
  );
}

export function DeletableValueOnUnmountSingleSelectCheckboxGroup(props) {
  return (
    <DeletableValueOnUnmountField {...props}>
      <SingleSelectCheckboxGroup {...props} />
    </DeletableValueOnUnmountField>
  );
}

export function MeasurementElementsField({ meta, ...otherProps }) {
  return (
    <>
      <MeasurementElements {...otherProps} />
      {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
    </>
  );
}

export function SwitchField({ input, label, defaultTrue, disabled }) {
  if (input.value !== defaultTrue && input.value === '' && defaultTrue !== undefined) {
    input.onChange(defaultTrue);
  }
  return (
    <FormControlLabel
      control={<Switch checked={!!input.value} onClick={() => input.onChange(!input.value)} disabled={disabled} />}
      label={label}
    />
  );
}

export class AddOnlyMapField extends React.Component {
  onChange = () => {
    const jsValue = this.currentValue;
    jsValue[this.textFieldRef.value] = true;
    this.textFieldRef.value = '';
    this.props.input.onChange(jsValue);
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.onChange();
    }
  };

  render() {
    const { input, label, textFieldProps, readonly = false } = this.props;
    this.currentValue = input.value;
    if (!this.currentValue) {
      this.currentValue = {};
    }
    return (
      <>
        {Object.keys(this.currentValue).map((key) => (
          <span key={key}>{`${key} `}</span>
        ))}
        {!readonly && (
          <div>
            <TextField
              {...textFieldProps}
              inputRef={(ref) => {
                this.textFieldRef = ref;
              }}
              onKeyDown={this.handleKeyDown}
              placeholder={label}
            />
            <Button onClick={this.onChange}>OK</Button>
          </div>
        )}
      </>
    );
  }
}

export function ConditionalField({ conditionalOnFieldName, children, ...rest }) {
  return (
    <Field
      name={conditionalOnFieldName}
      {...rest}
      component={(props) => {
        if (props.input.value) {
          return children;
        }
        return null;
      }}
    />
  );
}
