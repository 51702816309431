import {
  TELEMEDECINE_PAR_TELEPHONE,
  TELEMEDECINE_PAR_VISIOCONSULTATION,
  SERVICE_DISCONTINU_TELEMEDECINE
} from 'shared/ramq/contextElements/lettreEntente241ContextElements';
import Recommendation, { NO_RECOMMENDATIONS } from '../types';
import Act, { ActCode } from '../../../../shared/domain/activity/act/model/Act';
import { findCodeDetail } from '../../../../shared/data/actCodesData';

type CodePair = [string, string];
export const NONTELE_VS_TELEMEDICINE_CODES: CodePair[] = [
  ['9160', '9170'],
  ['9150', '9162'],
  ['9135', '9145'],
  ['9152', '9164']
];
const TELEMEDICINE_CONTEXT_ELEMENTS = [
  TELEMEDECINE_PAR_TELEPHONE,
  TELEMEDECINE_PAR_VISIOCONSULTATION,
  SERVICE_DISCONTINU_TELEMEDECINE
];
const NON_TELEMEDICINE_CODES_TO_SWITCH = NONTELE_VS_TELEMEDICINE_CODES.map((codePair) => codePair[0]);

const switchCodeForTelemedicine = (act: Partial<Act>): Recommendation<ActCode[] | undefined>[] => {
  if (!act.codes || !act.contextElements) {
    return NO_RECOMMENDATIONS;
  }
  const actCodesList = act.codes?.map((actCode) => actCode.code);
  if (
    !arrayIncludesAnyOf(act.contextElements, TELEMEDICINE_CONTEXT_ELEMENTS) ||
    !arrayIncludesAnyOf(actCodesList, NON_TELEMEDICINE_CODES_TO_SWITCH)
  ) {
    return NO_RECOMMENDATIONS;
  }
  return switchCodeForTelemedicineInAct(actCodesList!);
};

function arrayIncludesAnyOf(array: string[], values: string[]): boolean {
  return values.some((value) => array.includes(value));
}

function replaceCode(actCodes: ActCode[], codeToReplace: string, replacementCode: string) {
  return actCodes.map((actCode) => {
    if (actCode.code !== codeToReplace) {
      return actCode;
    }
    return findCodeDetail(replacementCode) as ActCode;
  });
}

function switchCodeForTelemedicineInAct(actCodesList: string[]): Recommendation<ActCode[] | undefined>[] {
  const invalidVsValidCodePairsInAct = NONTELE_VS_TELEMEDICINE_CODES.filter(([nonTeleCode]) =>
    actCodesList!.some((code) => code === nonTeleCode)
  );
  return invalidVsValidCodePairsInAct.map(([invalidCode, validCode]) => ({
    message: `Modification du code ${invalidCode} par ${validCode} pour la télémédecine.`,
    apply: (partialAct) => ({
      fieldName: 'codes',
      newValue: replaceCode(partialAct?.codes || [], invalidCode, validCode),
      oldValue: partialAct.codes
    })
  }));
}
export default switchCodeForTelemedicine;
