import SpecialtyName from '../shared/core/doctor/domain/SpecialtyName';
import {
  DISABLE_SURGERY_MAIN_CODE_MEASUREMENT_ELEMENTS,
  DETAILED_MIXTE_HOURS,
  FAVORITE_ACT_CODES_LIST_NAME,
  MIXTE_MAX_CODES_PER_DAY,
  MIXTE_CODE_LIST_NAME,
  LUMPSUM_CODE_LIST_NAME,
  ACT_ADDITIONAL_CONTEXT_ELEMENTS,
  ACT_DOCTOR_FIELD_VISIBLE,
  ACT_END_TIME_VISIBLE,
  ACT_REMP_TIME_VISIBLE,
  ACTIVITY_AREA_LIST,
  ACTIVITY_SECTOR_DEFAULT_OPEN,
  CODES_DATA_SPECIALTY_OVERRIDE,
  CODES_DATA_SPECIALTY_OVERRIDE_INTERNIST,
  CONTEXT_ELEMENT_LIST_NAME,
  REQUIRED_DETAILS_FOR_INTERNISTS,
  REQUIRED_DETAILS_FOR_PNEUMOLOGISTS,
  LIMITED_INTENSIVE_CARE_DIAGNOSTIC_LIST_NAME,
  DUPLICATE_ANOTHER_DAY_ACTS,
  SHOW_DIAGNOSTIC_IN_ACT_FORM,
  DEFAULT_START_TIME,
  LUMPSUM_MAX_CODES_PER_DAY,
  VALIDATE_R1_SURGERY_SUPPLEMENTS_CODE,
  MINIMUM_DURATION_BY_MIXTE_HALF_PERDIEM,
  MINIMUM_DURATION_BY_MIXTE_PERDIEMS,
  PM_PERDIEM_END_HOUR_FOR_OTHER_ACTS,
  URGENT_CARE_DETAIL_REQUIRED,
  DAILY_CHRONIC_PAIN_PLAN,
  ACT_HISTORY_WITH_NAM
} from './featureNames';
import {
  ANESTHESIOLOGIST_INTENSIVIST_ACTIVITY_AREA_SELECTION,
  ANESTHESIOLOGIST_ACTIVITY_AREA_SELECTION,
  INTERNIST_ACTIVITY_AREA_SELECTION
} from './constants';

export const FAVORITE_ACT_CODES_CARDIOLOGY_INSTITUTE = 'cardiology-institute-codes';
export const FAVORITE_ACT_CODES_INTERNIST = 'internist-codes';
export const LIMITED_INTENSIVE_CARE_DIAGNOSTIC_INTERNIST_LIST = 'limited-intensive-care-diagnostic-internist-list';

const anesthesiologistConfig = {
  [DISABLE_SURGERY_MAIN_CODE_MEASUREMENT_ELEMENTS]: true,
  [FAVORITE_ACT_CODES_LIST_NAME]: FAVORITE_ACT_CODES_CARDIOLOGY_INSTITUTE,
  [MIXTE_CODE_LIST_NAME]: 'mixte-specialist-codes',
  [LUMPSUM_CODE_LIST_NAME]: 'lumpSum-specialist-codes',
  [ACT_END_TIME_VISIBLE]: true,
  [ACT_REMP_TIME_VISIBLE]: true,
  [URGENT_CARE_DETAIL_REQUIRED]: true,
  [ACTIVITY_AREA_LIST]: ANESTHESIOLOGIST_ACTIVITY_AREA_SELECTION,
  [CONTEXT_ELEMENT_LIST_NAME]: 'anesthesiologist',
  [ACT_DOCTOR_FIELD_VISIBLE]: true,
  [LIMITED_INTENSIVE_CARE_DIAGNOSTIC_LIST_NAME]: 'limited-intensive-care-diagnostic-default-list',
  // eslint-disable-next-line global-require
  [SHOW_DIAGNOSTIC_IN_ACT_FORM]: false,
  [LUMPSUM_MAX_CODES_PER_DAY]: 6,
  [VALIDATE_R1_SURGERY_SUPPLEMENTS_CODE]: true,
  [MINIMUM_DURATION_BY_MIXTE_HALF_PERDIEM]: 3.5,
  [MINIMUM_DURATION_BY_MIXTE_PERDIEMS]: 7,
  [PM_PERDIEM_END_HOUR_FOR_OTHER_ACTS]: 21,
  [DAILY_CHRONIC_PAIN_PLAN]: true,
  [ACT_HISTORY_WITH_NAM]: false
};

const anesthesiologistIntensivistConfig = {
  ...anesthesiologistConfig,
  [ACTIVITY_AREA_LIST]: ANESTHESIOLOGIST_INTENSIVIST_ACTIVITY_AREA_SELECTION
};

const internistConfig = {
  [DETAILED_MIXTE_HOURS]: true,
  [FAVORITE_ACT_CODES_LIST_NAME]: FAVORITE_ACT_CODES_INTERNIST,
  [MIXTE_MAX_CODES_PER_DAY]: 6,
  [LUMPSUM_MAX_CODES_PER_DAY]: 6,
  [MIXTE_CODE_LIST_NAME]: 'mixte-internist-codes',
  [LUMPSUM_CODE_LIST_NAME]: 'lumpSum-internist-codes',
  [ACT_ADDITIONAL_CONTEXT_ELEMENTS]: false,
  [ACTIVITY_AREA_LIST]: INTERNIST_ACTIVITY_AREA_SELECTION,
  [ACTIVITY_SECTOR_DEFAULT_OPEN]: true,
  [CODES_DATA_SPECIALTY_OVERRIDE]: CODES_DATA_SPECIALTY_OVERRIDE_INTERNIST,
  [CONTEXT_ELEMENT_LIST_NAME]: 'internist',
  [REQUIRED_DETAILS_FOR_INTERNISTS]: true,
  [LIMITED_INTENSIVE_CARE_DIAGNOSTIC_LIST_NAME]: 'limited-intensive-care-diagnostic-internist-list',
  [DUPLICATE_ANOTHER_DAY_ACTS]: true,
  [SHOW_DIAGNOSTIC_IN_ACT_FORM]: false,
  [DEFAULT_START_TIME]: 8,
  [MINIMUM_DURATION_BY_MIXTE_HALF_PERDIEM]: 1,
  [MINIMUM_DURATION_BY_MIXTE_PERDIEMS]: 4.5,
  [PM_PERDIEM_END_HOUR_FOR_OTHER_ACTS]: 17,
  [ACT_HISTORY_WITH_NAM]: true
};

const cardiologist = {
  ...internistConfig
};

const endocrinologist = {
  ...internistConfig
};

const pneumologistConfig = {
  ...internistConfig,
  [SHOW_DIAGNOSTIC_IN_ACT_FORM]: true,
  [REQUIRED_DETAILS_FOR_PNEUMOLOGISTS]: true,
  [REQUIRED_DETAILS_FOR_INTERNISTS]: false,
  [LUMPSUM_CODE_LIST_NAME]: 'lumpSum-pneumologist-codes',
  [ACT_END_TIME_VISIBLE]: true,
  [ACT_HISTORY_WITH_NAM]: true
};

export default {
  [SpecialtyName.ANESTHESIOLOGIST]: anesthesiologistConfig,
  [SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST]: anesthesiologistIntensivistConfig,
  [SpecialtyName.INTERNIST]: internistConfig,
  [SpecialtyName.CARDIOLOGIST]: cardiologist,
  [SpecialtyName.ENDOCRINOLOGIST]: endocrinologist,
  [SpecialtyName.PNEUMOLOGIST]: pneumologistConfig
};
