/* eslint-disable react/prop-types */
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { debounce, get, isString, map, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import NamAdmissibilityWarning from 'application/components/nam-admissibility-warning';
import { DirectoryPhysicianType } from 'app/containers/ActForm/DoctorAutocomplete/DoctorAutocomplete';
import Modal from 'application/components/modal';
import ActivityType from '../../../shared/core/activity/domain/ActivityType';
import UserBillingType from '../../../shared/domain/user/UserBillingType';
import {
  ACTIVITY_SECTOR_DEFAULT_OPEN,
  ACT_DOCTOR_FIELD_VISIBLE,
  ACT_HISTORY_WITH_NAM,
  ACT_REMP_TIME_VISIBLE
} from '../../../userCustomization/featureNames';
import isDefined from '../../../shared/utils/isDefined';
import { getFeatureConfig } from '../../../userCustomization/featureToggle';
import { currencyFormat } from '../../../utils/wordFormatUtilities';
import ActivityAreaSelector from '../../activityArea/component/ActivityAreaSelector/ActivityAreaSelector';
import ActivityAreaChoiceContextFactory from '../../activityArea/domain/ActivityAreaChoiceContextFactory';
import ContextElementSelector from '../../components/contextElements/ContextElementsSelector/ContextElementsSelector';
import EditableField from '../../components/EditableField/EditableField';
import ActsHistoryWithNam from '../../components/Form/ActSpecificField/ActsWarnings/ActsHistoryWithNam';
import DefaultActValueDateField from '../../components/Form/ActSpecificField/DefaultActValueDateField/DefaultActValueDateField';
import DiagnosticField from '../../components/Form/ActSpecificField/Diagnostics/DiagnosticField';
import NamWithPrevious24HoursActsFetcher from '../../components/Form/ActSpecificField/NamWithPrevious24HoursActsFetcher/NamWithPrevious24HoursActsFetcher';
import ServiceProvidedInContextOfCatchingUpMedicalActivitiesWarning from '../../components/Form/ActSpecificField/ServiceProvidedInContextOfCatchingUpMedicalActivitiesWarning';
import BillingTypeField from '../../components/Form/BillingTypeField/BillingTypeField';
import CashAmountInput from '../../components/Form/custom/CashAmountInput';
import {
  AddOnlyMapField,
  DoctorAutocompleteField,
  NamField,
  NoteField,
  PlaceSelectField
} from '../../components/Form/Fields';
import FormElement from '../../components/Form/FormElement/FormElement';
import FormRow from '../../components/Form/FormRow/FormRow';
import MultiplePlacesOnSameDayWarning from '../../components/Form/MultiplePlacesOnSameDayWarning/MultiplePlacesOnSameDayWarning';
import NamIcon from '../../components/Form/NamInput/NamIcon/NamIcon';
import TimePickerSyncedWithDate from '../../components/Form/TimePickerSyncedWithDate/TimePickerSyncedWithDate';
import TimeSyncedWithGivenTime from '../../components/Form/TimeSyncedWithGivenTime/TimeSyncedWithGivenTime';
import WarningsDisplay from '../../components/Form/WarningsDisplay/WarningsDisplay';
import OnTopBottomNavigationPortal from '../../components/Portals/OnTopBottomNavigationPortal/OnTopBottomNavigationPortal';
import RecommendationBox from '../../components/Recommendation/RecommendationBox';
import ActRequiredDetails from '../../components/RequiredDetails/ActRequiredDetails';
import Status from '../../components/Status/Status';
import warningService from '../../validations/act/codes/warningService';
import { isValidNam } from '../../validations/act/nam/namValidation';
import OutsideRamqFields from '../../validations/act/outsideRamq/OutsideRamqFields';
import { scrollToInvalidField } from '../../validations/common/reduxFormValidation';
import AttachFile from '../Attachments/AttachFile/AttachFile';
import AttachmentsList from '../Attachments/AttachmentsList/AttachmentsList';
import { SYNCHRONIZED_ATTACHMENT } from '../Storage/cordova/constants';
import { selectUserProfileInContext } from '../User/selectors';
import {
  actFileReadyForUpload,
  closePicturePreview,
  deleteSavedPicture,
  fetchUpToDatePatientContextAccordingToNam,
  togglePicturePreview,
  updateUrgentCallDetails
} from './actions';
import Camera from './Camera/Camera';
import CodeListWithRequiredDetails from './CodeListWithRequiredDetails/CodeListWithRequiredDetails';
import { actFormAdminFields, actFormFields, ACT_FORM_NAME } from './constants';
import CorrectionDisplay from './CorrectionDisplay/CorrectionDisplay';
import DateSelector from './DateSelector/DateSelector';
import DetectedNamsDialog from './DetectedNamsDialog/DetectedNamsDialog';
import messages from './messages';
import NoNamFormAdmin from './NoNamForm/Admin/NoNamFormAdmin';
import NoNamFormDoctor from './NoNamForm/Doctor/NoNamFormDoctor';
import PatientEvent from './PatientEvent/PatientEvent';
import PicturePreview from './PicturePreview/PicturePreview';
import {
  getSavedPicture,
  getSelectedPlace,
  selectBillingType,
  selectContextElements,
  selectCurrentCodes,
  selectExtractedNams,
  selectUrgentCallDetails,
  selectNam,
  selectNamSelectorDialogState,
  selectPatientInformation,
  selectPatientType,
  selectPicturePreviewDisplayed,
  selectRempTime,
  selectSelectedDate,
  selectStartTime,
  selectCurrentActForm,
  selectRamqPatientNamIsAdmissible
} from './selectors';
import styles from './styles';
import SingleCheckbox from '../../components/Form/Checkbox/SingleCheckbox/SingleCheckbox';
import BillingType from '../../../shared/domain/billing/model/BillingType';
import PatientIdentificationTypeDropDown from '../../components/PatientIdentificationTypeDropDown/PatientIdentificationTypeDropDown';
import NoNamCollapsableIcon from '../../components/CollapsableIcon/NoNamCollapsableIcon/NoNamCollapsableIcon';
import UrgentCallDetails from './UrgentCallDetails/UrgentCallDetails';
import RamqPatient from './RamqPatient';

export class ActForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      noNamFormVisible: false,
      showCancelationConfirmationModal: false
    };
  }

  toggleNoNamForm = () => {
    this.setState((prevState) => ({ noNamFormVisible: !prevState.noNamFormVisible }));
  };

  onChangeNam = (value) => {
    const nam = map(pickBy(value, isString)).join('');
    const userId = this.props.userProfile.id;
    if (nam.length >= 12 && isValidNam(nam)) {
      this.props.fetchUpToDatePatientContextAccordingToNam(nam, userId);
    }
  };

  isPoolBillingType = (props) => props.userProfile.billingType === UserBillingType.POOL;

  getDefaultPoolNumber = () => {
    const { userProfile } = this.props;

    if (!userProfile.pools || userProfile.pools.length === 0) {
      return null;
    }

    return userProfile.pools[0].number;
  };

  onBillingTypeChanged = (newBillingType) => {
    const { change } = this.props;

    change(actFormFields.guard, false);

    if (newBillingType === BillingType.PRIV) {
      change(actFormFields.poolNumber, null);
    } else {
      change(actFormFields.poolNumber, this.getDefaultPoolNumber());
    }
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.act.id !== this.props.act.id) {
      this.initializeForm(nextProps.act);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { act } = this.props;

    this.initializeForm(act);

    if (isDefined(act.nam)) {
      this.onChangeNam(act.nam);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.namState === prevProps.namState) return;

    if (isDefined(this.props.namState)) {
      this.onChangeNam(this.props.namState);
    }
  }

  initializeForm(act) {
    const insuranceAlowMedyxToContactWithPatient = get(
      act,
      'patientInformation.insurance.allowMedyxToContactWithPatient'
    );
    const outsideRamqAllowMedyxToContactWithPatient = get(
      act,
      'patientInformation.outsideRamq.allowMedyxToContactWithPatient'
    );

    let patientInformation = { ...act.patientInformation };
    // MED-2612 Insurance field no longer exists and was moved to OutsideRamq and we
    // still want to support old forms created with Insurance so we use that value if
    // none was defined in OutsideRamq
    if (insuranceAlowMedyxToContactWithPatient && !outsideRamqAllowMedyxToContactWithPatient) {
      patientInformation = {
        ...patientInformation,
        outsideRamq: {
          ...patientInformation.outsideRamq,
          allowMedyxToContactWithPatient: insuranceAlowMedyxToContactWithPatient
        }
      };
    }

    patientInformation = { type: 'NAM', ...patientInformation };

    if ([BillingType.POOL, BillingType.END].includes(act.billingType) && !act.poolNumber) {
      Object.assign(act, { poolNumber: this.getDefaultPoolNumber() });
    }

    this.props.initialize({ ...act, patientInformation });
  }

  handleOpenCancelConfirmationModal = () => {
    this.setState({ showCancelationConfirmationModal: true });
  };
  handleCloseCancelConfirmationModal = () => {
    this.setState({ showCancelationConfirmationModal: false });
  };
  cancelActivity = () => {
    this.setState({ showCancelationConfirmationModal: false });
    this.props.handleSubmit(this.props.onCancel)();
  };

  handleCancelClick = () => {
    if (!this.props.isBillNumberMapEmpty()) {
      this.handleOpenCancelConfirmationModal();
    } else {
      this.props.handleSubmit(this.props.onCancel)();
    }
  };

  render() {
    const {
      handleSubmit,
      onSent,
      onWait,
      onSave,
      onCancel,
      act,
      patientInformation,
      classes,
      namSelectorDialogState,
      savedPicture,
      picturePreviewOpen,
      onClosePicturePreview,
      onTogglePicturePreview,
      onDeletePicturePreview,
      userProfile,
      patientType,
      namState,
      namIsAdmissible,
      billingType,
      extractedNams,
      urgentCallDetails,
      currentAct
    } = this.props;
    const { showCancelationConfirmationModal } = this.state;
    return (
      <Fade in>
        <>
          <Modal
            open={showCancelationConfirmationModal}
            title={<FormattedMessage id="activity-cancellation-contirmation-dialog.title" />}
            actions={[
              {
                label: <FormattedMessage id="activity-cancellation-contirmation-dialog.cancel" />,
                onClick: () => this.handleCloseCancelConfirmationModal(),
                variant: 'ghost'
              },
              {
                label: <FormattedMessage id="activity-cancellation-contirmation-dialog.confirm" />,
                onClick: () => this.cancelActivity(),
                variant: 'primary'
              }
            ]}
          >
            <p>
              <FormattedMessage id="activity-cancellation-contirmation-dialog.description" />
            </p>
            <p className="mt-4">
              <FormattedMessage id="activity-cancellation-contirmation-dialog.confirmation-message" />
            </p>
          </Modal>
          {this.isPoolBillingType(this.props) && (
            <BillingTypeField
              billingTypeFieldName={actFormFields.billingType}
              poolNumberFieldName={actFormFields.poolNumber}
              currentBillingType={billingType}
              user={userProfile}
              onChange={this.onBillingTypeChanged}
            />
          )}
          <Paper id="act-form-paper" className={classes.card}>
            <DetectedNamsDialog open={namSelectorDialogState.open} detectedNams={namSelectorDialogState.detectedNams} />
            <FormElement
              name={actFormFields.corrections}
              component={CorrectionDisplay}
              status={act.status}
              onAcknowledgeCorrectionClick={handleSubmit(onSent)}
            />
            <CardContent className={classes.cardContainer}>
              <form className={classes.formContainer}>
                <FormRow>
                  <FormElement
                    name={actFormFields.date}
                    label={<FormattedMessage id="act-form.date" />}
                    component={DateSelector}
                    posthogEvent="Act form date changed"
                  />
                  {billingType === BillingType.POOL && (
                    <FormElement
                      name={actFormFields.guard}
                      label={<FormattedMessage id="act-form.guard" />}
                      component={SingleCheckbox}
                    />
                  )}
                  {act.status && <Status status={act.status} />}
                </FormRow>
                <FormRow className={classes.placeContainer}>
                  <FormElement
                    name={actFormFields.place}
                    label={<FormattedMessage id="act-form.location" />}
                    component={PlaceSelectField}
                  />
                  <ActivityAreaSelector
                    formField={actFormFields.activityArea}
                    initiallyOpen={getFeatureConfig(ACTIVITY_SECTOR_DEFAULT_OPEN, false)}
                    activityAreas={ActivityAreaChoiceContextFactory.create(ActivityType.ACT, userProfile.specialty)}
                  />
                </FormRow>
                <FormRow className={classes.namContainer}>
                  <div className={classes.namFieldWrapper}>
                    <FieldArray
                      name={actFormFields.assets}
                      component={Camera}
                      savedPicture={savedPicture}
                      togglePicturePreview={onTogglePicturePreview}
                      onFileReadyForUpload={(...args) => this.props.actFileReadyForUpload(act.id, ...args)}
                    />

                    {patientType === 'NAM' && (
                      <div className={classes.patientContainer}>
                        <div className={classes.namWrapper}>
                          <FormElement
                            className={classes.namTextInput}
                            name={actFormFields.nam}
                            label=""
                            fullWidth
                            component={NamField}
                            onChange={debounce(this.onChangeNam, 1000)}
                            posthogCaptureManualEntry
                          />
                          {namState.length === 12 && (
                            <div className={classes.namIndicator}>
                              <NamIcon nam={namState} isAdmissible={namIsAdmissible} />
                            </div>
                          )}
                        </div>
                        <div className={classes.ramqPatientInformations}>
                          <RamqPatient actDate={this.props.actDate} nam={namState} name={actFormFields.ramqPatient} />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={classes.patientIdentificationForm}>
                    <PatientIdentificationTypeDropDown />
                    <Restrict atLeastRole={Role.Agent}>
                      <NoNamCollapsableIcon
                        noNamFormVisible={this.state.noNamFormVisible}
                        toggleNoNamForm={this.toggleNoNamForm}
                      />
                    </Restrict>
                  </div>
                </FormRow>

                <NamAdmissibilityWarning className={classes.namAdmissibilityWarning} />

                <NamWithPrevious24HoursActsFetcher />

                {getFeatureConfig(ACT_HISTORY_WITH_NAM, true) && (
                  <ActsHistoryWithNam nam={namState} user={userProfile} />
                )}

                <MultiplePlacesOnSameDayWarning currentActivity={act} />

                <FormElement name={actFormFields.warningsDisplay} component={WarningsDisplay} />

                <RecommendationBox act={currentAct} nam={namState} />

                <PicturePreview
                  actId={act.id}
                  savedPicture={savedPicture}
                  open={picturePreviewOpen}
                  closePicturePreview={onClosePicturePreview}
                  deleteSavedPicture={onDeletePicturePreview}
                  extractedNams={extractedNams}
                  focusedNam={namState}
                />

                <DiagnosticField patientInformation={patientInformation} userProfile={userProfile} />

                <FormRow
                  className={
                    OutsideRamqFields.isDateHospitalisationFieldRequired(patientInformation)
                      ? ''
                      : classes.nonDisplayedElement
                  }
                >
                  <FormElement
                    label="Date d'hospitalisation"
                    required={OutsideRamqFields.isDateHospitalisationFieldRequired(patientInformation)}
                    component={DefaultActValueDateField}
                    name={actFormFields.dateHospitalisation}
                  />
                </FormRow>

                <Restrict atLeastRole={Role.Agent}>
                  <NoNamFormAdmin formVisible={this.state.noNamFormVisible} />
                </Restrict>
                <PatientEvent
                  className={classes.patientEventType}
                  name={actFormFields.patientEvent}
                  nam={namState}
                  user={userProfile}
                />
                <Restrict isRole={Role.Doctor}>
                  <NoNamFormDoctor formVisible />
                </Restrict>
                <FormRow className={classes.timeSelectorsContainer}>
                  {/* The reason we use render functions here instead of component name is because we need to make
                 sure those components rerender when the act form is only updated (not re-rendered). Not doing that makes the hour
                 synchronization logic too complicated and leaves the hour of the previous act in place. We only see this behavior
                 in the admin console because there is no way in doctor app right now to NOT re render act form every time */}
                  <FormElement
                    name={actFormFields.start}
                    label={<FormattedMessage {...messages.start} />}
                    component={TimePickerSyncedWithDate}
                    dateSelector={selectSelectedDate}
                  />
                  <FormElement
                    name={actFormFields.end}
                    required={false}
                    label={<FormattedMessage {...messages.end} />}
                    component={TimeSyncedWithGivenTime}
                    givenTime={this.props.rempTime || this.props.startTime}
                  />
                  <FormElement
                    className={getFeatureConfig(ACT_REMP_TIME_VISIBLE, false) ? '' : classes.invisibleElement}
                    name={actFormFields.remp}
                    label={<FormattedMessage {...messages.remp} />}
                    component={TimeSyncedWithGivenTime}
                    givenTime={this.props.startTime}
                  />
                </FormRow>

                <ServiceProvidedInContextOfCatchingUpMedicalActivitiesWarning />

                <FieldArray
                  name={actFormFields.codes}
                  label={<FormattedMessage {...messages.codes} />}
                  component={CodeListWithRequiredDetails}
                />

                <ActRequiredDetails />

                <Typography gutterBottom variant="h6">
                  <FormattedMessage id="act-form.context.context" />
                </Typography>

                <Divider />

                <ContextElementSelector />

                <UrgentCallDetails
                  act={currentAct}
                  urgentCallDetails={urgentCallDetails}
                  updateUrgentCallDetails={this.props.updateUrgentCallDetails}
                />

                {getFeatureConfig(ACT_DOCTOR_FIELD_VISIBLE, false) && (
                  <FormRow>
                    <FormElement
                      name={actFormFields.doctor}
                      required={OutsideRamqFields.isDoctorRequired(patientInformation, userProfile)}
                      label={<FormattedMessage {...messages.doctor} />}
                      type={DirectoryPhysicianType.PHYSICIAN}
                      component={DoctorAutocompleteField}
                    />
                  </FormRow>
                )}
                <FormRow>
                  <FormElement
                    name={actFormFields.note}
                    label={<FormattedMessage {...messages.note} />}
                    component={NoteField}
                  />
                  <AttachFile
                    attachmentType={SYNCHRONIZED_ATTACHMENT}
                    documentId={act.id}
                    fieldName={actFormFields.uploadAttachment}
                    fileReadyForUploadHandler={this.props.actFileReadyForUpload}
                  />
                </FormRow>
                <FormRow>
                  <FieldArray name={actFormFields.attachments} component={AttachmentsList} />
                </FormRow>
                <Restrict atLeastRole={Role.Agent}>
                  <Grid container className={classes.adminContainer}>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom variant="h6">
                        Numéros de facture
                      </Typography>
                      <FormElement
                        textFieldProps={{ type: 'number' }}
                        name={actFormAdminFields.billNumberMap}
                        readonly
                        component={AddOnlyMapField}
                      />
                      <FormElement
                        textFieldProps={{ type: 'number' }}
                        name={actFormAdminFields.billNumbersToRecover}
                        label="# facture"
                        component={AddOnlyMapField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography gutterBottom variant="h6">
                        Montant Facturé
                      </Typography>
                      <div className={classes.manualCorrection}>
                        <Field
                          name={actFormAdminFields.manualCorrection}
                          component={({ input }) => {
                            const initialValue = input.value || '0.00';

                            return (
                              <EditableField
                                initialValue={initialValue}
                                valueFormatter={currencyFormat}
                                edited={!!input.value}
                                EditingComponent={CashAmountInput}
                                onSave={(value) => {
                                  input.onChange(value);
                                }}
                                onDelete={() => {
                                  input.onChange(null);
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                      <Typography gutterBottom variant="h6">
                        Montant Payé (si Diff.)
                      </Typography>
                      <div className={classes.paidAmount}>
                        <Field
                          name={actFormAdminFields.paidAmount}
                          component={({ input }) => {
                            const initialValue = input.value || '0.00';

                            return (
                              <EditableField
                                initialValue={initialValue}
                                valueFormatter={currencyFormat}
                                edited={!!input.value}
                                EditingComponent={CashAmountInput}
                                onSave={(value) => {
                                  input.onChange(value);
                                }}
                                onDelete={() => {
                                  input.onChange(null);
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Restrict>
                <OnTopBottomNavigationPortal>
                  <div className={classes.submitButtonGroup}>
                    {onSave && (
                      <Button id="save-act-button" onClick={handleSubmit(onSave)} variant="contained">
                        Enregistrer
                      </Button>
                    )}
                    {onCancel && (
                      <Button
                        id="cancel-act-button"
                        onClick={this.handleCancelClick}
                        variant="contained"
                        className={classes.cancelButton}
                      >
                        <FormattedMessage id="act-form.cancel" />
                      </Button>
                    )}
                    <Button id="wait-act-button" onClick={handleSubmit(onWait)} variant="contained">
                      <FormattedMessage id="act-form.wait" />
                    </Button>
                    <Button id="send-act-button" onClick={handleSubmit(onSent)} variant="contained" color="primary">
                      <FormattedMessage id="act-form.send" />
                    </Button>
                  </div>
                </OnTopBottomNavigationPortal>
              </form>
            </CardContent>
          </Paper>
        </>
      </Fade>
    );
  }
}

ActForm.defaultProps = {
  act: {},
  patientInformation: {},
  onSave: undefined,
  selectedContextElements: [],
  selectedCodes: [],
  picturePreviewOpen: false,
  onClosePicturePreview: undefined,
  onTogglePicturePreview: undefined,
  actDate: 0,
  startTime: 0,
  rempTime: undefined,
  patientType: '',
  namState: '',
  billingType: undefined,
  urgentCallDetails: undefined,
  currentAct: {}
};

ActForm.propTypes = {
  act: PropTypes.object,
  patientInformation: PropTypes.object,
  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
  patientType: PropTypes.string,
  place: PropTypes.object.isRequired,
  actDate: PropTypes.number,
  startTime: PropTypes.number,
  rempTime: PropTypes.number,
  onSent: PropTypes.func.isRequired,
  onWait: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  selectedContextElements: PropTypes.array,
  selectedCodes: PropTypes.array,
  picturePreviewOpen: PropTypes.bool,
  onClosePicturePreview: PropTypes.func,
  onTogglePicturePreview: PropTypes.func,
  urgentCallDetails: PropTypes.object,
  currentAct: PropTypes.object
};

export const mapStateToProps = createStructuredSelector({
  namSelectorDialogState: selectNamSelectorDialogState(),
  savedPicture: getSavedPicture(),
  picturePreviewOpen: selectPicturePreviewDisplayed(),
  selectedContextElements: selectContextElements(),
  selectedCodes: selectCurrentCodes(),
  userProfile: selectUserProfileInContext(),
  place: getSelectedPlace(),
  actDate: selectSelectedDate(),
  patientInformation: selectPatientInformation(),
  startTime: selectStartTime(),
  rempTime: selectRempTime(),
  patientType: selectPatientType(),
  namState: selectNam(),
  namIsAdmissible: selectRamqPatientNamIsAdmissible(),
  billingType: selectBillingType(),
  extractedNams: selectExtractedNams(),
  urgentCallDetails: selectUrgentCallDetails(),
  currentAct: selectCurrentActForm()
});

export const mapDispatchToProps = {
  actFileReadyForUpload,
  fetchUpToDatePatientContextAccordingToNam,
  onClosePicturePreview: closePicturePreview,
  onTogglePicturePreview: togglePicturePreview,
  onDeletePicturePreview: deleteSavedPicture,
  updateUrgentCallDetails
};

export default compose(
  withStyles(styles),
  reduxForm({
    form: ACT_FORM_NAME,
    onSubmitFail: scrollToInvalidField,
    warn: warningService
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(ActForm);
