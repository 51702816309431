import moment from 'moment-timezone';

export const MALE = 'male';
export const FEMALE = 'female';

export const parseNam = (nam) => ({
  name: nam.slice(0, 4),
  year: parseInt(nam.slice(4, 6), 10),
  sex: sexFromNamMonth(nam.slice(6, 8)),
  month: monthFromNam(nam.slice(6, 8)),
  day: parseInt(nam.slice(8, 10), 10)
});

const sexFromNamMonth = (namMonth) => {
  const intNamMonth = parseInt(namMonth, 10);
  return intNamMonth > 50 ? FEMALE : MALE;
};

const monthFromNam = (relevantNumberFromNam) => parseInt(relevantNumberFromNam, 10) % 50;

export const patientIsMinor = (nam) => getPatientMinimumAgeFromNamObject(parseNam(nam)) < 18;

export const patientIsElder = (nam, shiftNamCentury) =>
  getPatientMinimumAgeFromNamObject(parseNam(nam), shiftNamCentury) > 75;

export const getPatientMinimumAge = (nam) => getPatientMinimumAgeFromNamObject(parseNam(nam));

const getPatientMinimumAgeFromNamObject = (namObject, shiftNamCentury = false) => {
  const currentYear = moment().year();
  const currentCentury = currentYear - (currentYear % 100);

  let yearOfBirth = currentCentury + namObject.year;

  if (shiftNamCentury || currentYear < yearOfBirth) {
    yearOfBirth -= 100;
  }

  const currentCenturyBirthDateString = `${yearOfBirth}-${namObject.month < 10 ? 0 : ''}${namObject.month}-${
    namObject.day < 10 ? 0 : ''
  }${namObject.day}`;

  const currentCenturyBirthDate = moment(currentCenturyBirthDateString);
  const differenceWithNow = Math.floor(moment().diff(currentCenturyBirthDate, 'years', true));

  return differenceWithNow >= 0 ? differenceWithNow : 100 + differenceWithNow;
};

export const namFormula = (nam, century) =>
  `${nam.slice(0, 4)}${century}${nam.slice(4, 6)}${sexAndMonth(nam.slice(6, 8))}${nam.slice(8, 11)}`;

const sexAndMonth = (relevantNumberFromNam) => {
  const intValue = parseInt(relevantNumberFromNam, 10);
  return intValue > 50 ? calculateMonthOfBirthForWoman(intValue) : calculateMonthOfBirthForMan(intValue);
};

const calculateMonthOfBirthForMan = (intValue) => (intValue < 10 ? `M0${intValue}` : `M${intValue}`);

const calculateMonthOfBirthForWoman = (intValue) => {
  const valueMinus50 = intValue - 50;
  return valueMinus50 < 10 ? `F0${valueMinus50}` : `F${valueMinus50}`;
};
