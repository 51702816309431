import PlaceDomain from '../../domain/place/model/PlaceDomain';
import Act, { ActCode } from '../../domain/activity/act/model/Act';
import ActCodes from '../../domain/activity/act/model/ActCodes';

type WeekCode = string;
type WeekendCode = string;

const WEEK_AND_WEEKEND_GENERIC_ACT_CODES_MAPPING: Array<[WeekCode, WeekendCode]> = [
  ['28', '29'],
  ['39', '41'],
  ['832', '833'],
  ['991', '41030'],
  ['927', '41029'],
  ['9152', '9161'],
  ['9221', '9224'],
  ['9185', '9174'],
  ['9136', '9143'],
  ['9164', '15258'],
  ['9772', '9770'],
  ['9851', '9850'],
  ['15010', '15011'],
  ['15672', '15674'],
  ['15341', '15342'],
  ['15237', '15238'],
  ['19834', '19833'],
  ['41040', '41041'],
  ['41042', '41043'],
  ['41051', '41052'],
  ['41053', '41054'],
  ['41023', '41028'],
  ['41024', '41025']
];

const WEEK_AND_WEEKEND_GUARD_CODES_MAPPING: Array<[WeekCode, WeekendCode]> = [
  ['9891', '9892'],
  ['9833', '9832'],
  ['9835', '9834'],
  ['9749', '9748'],
  ['9747', '9746'],
  ['9703', '9701'],
  ['9702', '9700'],
  ['19096', '19094'],
  ['19095', '19093'],
  ['9717', '9716'],
  ['9715', '9714'],
  ['19877', '19875'],
  ['19876', '19874'],
  ['9773', '9774'],
  ['19340', '19341'],
  ['15859', '15861'],
  ['19624', '19625'],
  ['19906', '19907'],
  ['19912', '19913'],
  ['19129', '19130'],
  ['19137', '19138'],
  ['19800', '19801'],
  ['19135', '19136'],
  ['19131', '19132'],
  ['19133', '19134'],
  ['19295', '19296'],
  ['19303', '19304'],
  ['19802', '19803'],
  ['19301', '19302'],
  ['19297', '19298'],
  ['19299', '19300'],
  ['19349', '19350'],
  ['42233', '42234'],
  ['19405', '19406'],
  ['19427', '19428'],
  ['19363', '19364'],
  ['19141', '19142'],
  ['19145', '19146'],
  ['19151', '19152'],
  ['19143', '19144'],
  ['19147', '19148'],
  ['19153', '19154'],
  ['19155', '19156'],
  ['19149', '19150'],
  ['19157', '19158'],
  ['42004', '42005'],
  ['19159', '19160'],
  ['19161', '19162'],
  ['19932', '19933'],
  ['19479', '19480'],
  ['42006', '42007'],
  ['19481', '19482'],
  ['19538', '19539'],
  ['19351', '19352'],
  ['19570', '19571'],
  ['42032', '42033'],
  ['19637', '19638'],
  ['42241', '42242'],
  ['42216', '42217'],
  ['19163', '19164'],
  ['42154', '42155'],
  ['19173', '19174'],
  ['19167', '19168'],
  ['19165', '19166'],
  ['19540', '19541'],
  ['19542', '19543'],
  ['19544', '19545'],
  ['19169', '19170'],
  ['42026', '42027'],
  ['19171', '19172'],
  ['42243', '42244'],
  ['42218', '42219'],
  ['19305', '19306'],
  ['42156', '42157'],
  ['19315', '19316'],
  ['19309', '19310'],
  ['19307', '19308'],
  ['19556', '19557'],
  ['19558', '19559'],
  ['19560', '19561'],
  ['19311', '19312'],
  ['42028', '42029'],
  ['19313', '19314'],
  ['19867', '19868'],
  ['42030', '42031'],
  ['42235', '42236'],
  ['42245', '42246'],
  ['19407', '19408'],
  ['19777', '19778'],
  ['19572', '19573'],
  ['42247', '42248'],
  ['19429', '19430'],
  ['19779', '19780'],
  ['19588', '19589'],
  ['19363', '19364'],
  ['19175', '19176'],
  ['19889', '19890'],
  ['19483', '19484'],
  ['19177', '19178'],
  ['42008', '42009'],
  ['42140', '42141'],
  ['42142', '42143'],
  ['42010', '42011'],
  ['19179', '19180'],
  ['19181', '19182'],
  ['19183', '19184'],
  ['19185', '19186'],
  ['19187', '19188'],
  ['19485', '19486'],
  ['19191', '19192'],
  ['19189', '19190'],
  ['19487', '19488'],
  ['19193', '19194'],
  ['42012', '42013'],
  ['19934', '19935'],
  ['42014', '42015'],
  ['19195', '19196'],
  ['19197', '19198'],
  ['19203', '19204'],
  ['19546', '19547'],
  ['19199', '19200'],
  ['19548', '19549'],
  ['19201', '19202'],
  ['42096', '42097'],
  ['19205', '19206'],
  ['42016', '42017'],
  ['19861', '19862'],
  ['19207', '19208'],
  ['19804', '19805'],
  ['19863', '19864'],
  ['42220', '42221'],
  ['42237', '42238'],
  ['19317', '19318'],
  ['19806', '19807'],
  ['19865', '19866'],
  ['42222', '42223'],
  ['42239', '42240'],
  ['19353', '19354'],
  ['19944', '19945'],
  ['19946', '19947'],
  ['42179', '42180'],
  ['19562', '19563'],
  ['19600', '19601'],
  ['42197', '42198'],
  ['42098', '42099'],
  ['42251', '42252'],
  ['19409', '19410'],
  ['42150', '42151'],
  ['19658', '19659'],
  ['42034', '42035'],
  ['19602', '19603'],
  ['19574', '19575'],
  ['19604', '19605'],
  ['42036', '42037'],
  ['19781', '19782'],
  ['42040', '42041'],
  ['19606', '19607'],
  ['19576', '19577'],
  ['19639', '19640'],
  ['19810', '19811'],
  ['19411', '19412'],
  ['19431', '19432'],
  ['19209', '19210'],
  ['19211', '19212'],
  ['42018', '42019'],
  ['19215', '19216'],
  ['19489', '19490'],
  ['19217', '19218'],
  ['19219', '19220'],
  ['19656', '19657'],
  ['19223', '19224'],
  ['19221', '19222'],
  ['19225', '19226'],
  ['19227', '19228'],
  ['19491', '19492'],
  ['19239', '19240'],
  ['42020', '42021'],
  ['19499', '19500'],
  ['19550', '19551'],
  ['19241', '19242'],
  ['42022', '42023'],
  ['19243', '19244'],
  ['19229', '19230'],
  ['19495', '19496'],
  ['19497', '19498'],
  ['19235', '19236'],
  ['19237', '19238'],
  ['19233', '19234'],
  ['19231', '19232'],
  ['19493', '19494'],
  ['19319', '19320'],
  ['19531', '19532'],
  ['19533', '19534'],
  ['19325', '19326'],
  ['19327', '19328'],
  ['19323', '19324'],
  ['19321', '19322'],
  ['19529', '19530'],
  ['19355', '19356'],
  ['19357', '19358'],
  ['42144', '42145'],
  ['19564', '19565'],
  ['19578', '19579'],
  ['19413', '19414'],
  ['19580', '19581'],
  ['42046', '42047'],
  ['19415', '19416'],
  ['19417', '19418'],
  ['19433', '19434'],
  ['19419', '19420'],
  ['19608', '19609'],
  ['19421', '19422'],
  ['42042', '42043'],
  ['19259', '19260'],
  ['19261', '19262'],
  ['19213', '19214'],
  ['19265', '19266'],
  ['19267', '19268'],
  ['19263', '19264'],
  ['19275', '19276'],
  ['42024', '42025'],
  ['19277', '19278'],
  ['19552', '19553'],
  ['19283', '19284'],
  ['19279', '19280'],
  ['42146', '42147'],
  ['19285', '19286'],
  ['19503', '19504'],
  ['19287', '19288'],
  ['19281', '19282'],
  ['19289', '19290'],
  ['19291', '19292'],
  ['19505', '19506'],
  ['19245', '19246'],
  ['19501', '19502'],
  ['19253', '19254'],
  ['19251', '19252'],
  ['19249', '19250'],
  ['19247', '19248'],
  ['19257', '19258'],
  ['19255', '19256'],
  ['19596', '19597'],
  ['19936', '19937'],
  ['19269', '19270'],
  ['19273', '19274'],
  ['19271', '19272'],
  ['19329', '19330'],
  ['19535', '19536'],
  ['19337', '19338'],
  ['19335', '19336'],
  ['19333', '19334'],
  ['19331', '19332'],
  ['19367', '19368'],
  ['19365', '19366'],
  ['19598', '19599'],
  ['19938', '19939'],
  ['19343', '19344'],
  ['19347', '19348'],
  ['19345', '19346'],
  ['19359', '19360'],
  ['19566', '19567'],
  ['19582', '19583'],
  ['19586', '19587'],
  ['19105', '19106'],
  ['19831', '19832'],
  ['19423', '19424'],
  ['19425', '19426'],
  ['19127', '19128'],
  ['42000', '42001'],
  ['19594', '19595'],
  ['19891', '19892'],
  ['19568', '19569'],
  ['19812', '19813'],
  ['19818', '19819'],
  ['19610', '19611'],
  ['19584', '19585'],
  ['19435', '19436'],
  ['19814', '19815'],
  ['19820', '19821'],
  ['19612', '19613'],
  ['19590', '19591'],
  ['19507', '19508'],
  ['19511', '19512'],
  ['19940', '19941'],
  ['19517', '19518'],
  ['19513', '19514'],
  ['19525', '19526'],
  ['19519', '19520'],
  ['19509', '19510'],
  ['19521', '19522'],
  ['19527', '19528'],
  ['19515', '19516'],
  ['19523', '19524'],
  ['42148', '42149'],
  ['19942', '19943'],
  ['42044', '42045'],
  ['19293', '19294'],
  ['19554', '19555'],
  ['19361', '19362'],
  ['19139', '19140'],
  ['19808', '19809'],
  ['42002', '42003']
];

export const WEEK_AND_WEEKEND_ACT_CODES_MAPPING = [
  ...WEEK_AND_WEEKEND_GENERIC_ACT_CODES_MAPPING,
  ...WEEK_AND_WEEKEND_GUARD_CODES_MAPPING
];

export enum Mapping {
  GENERIC = 'WEEK_AND_WEEKEND_GENERIC_ACT_CODES_MAPPING',
  GUARD = 'WEEK_AND_WEEKEND_GUARD_CODES_MAPPING'
}

class WeekAndWeekendActCodesMappingService {
  constructor(private mapping: Array<[WeekCode, WeekendCode]> = WEEK_AND_WEEKEND_ACT_CODES_MAPPING) {}

  public useMapping(listToUse: Mapping): WeekAndWeekendActCodesMappingService {
    switch (listToUse) {
      case Mapping.GENERIC:
        this.mapping = WEEK_AND_WEEKEND_GENERIC_ACT_CODES_MAPPING;
        break;
      case Mapping.GUARD:
        this.mapping = WEEK_AND_WEEKEND_GUARD_CODES_MAPPING;
        break;
      default:
        this.mapping = WEEK_AND_WEEKEND_ACT_CODES_MAPPING;
        break;
    }

    return this;
  }

  public listMisusedCodesInAct({ date, place, codes }: Act): [string, string][] {
    const placeDomain = PlaceDomain.fromDto(place);
    const actCodes = new ActCodes(codes);
    const codesThatCantBeUsedThatDay = this.getAlternateCodesAccordingToDayType(date, placeDomain);
    const wrongCodes = actCodes.filter(({ code }) => codesThatCantBeUsedThatDay.includes(code));

    return wrongCodes.flatten().map(this.mapCode);
  }

  public getWeekCodes(): string[] {
    return this.mapping.map(([weekCode]) => weekCode);
  }

  public getWeekendCodes(): string[] {
    return this.mapping.map(([, weekendCode]) => weekendCode);
  }

  public findTupleByCode(code): [WeekCode, WeekendCode] | undefined {
    return this.mapping.find(([weekCode, weekendCode]) => weekCode === code || weekendCode === code);
  }

  private getAlternateCodesAccordingToDayType(date, placeDomain: PlaceDomain) {
    if (placeDomain.isWeekEndOrHoliday(date)) {
      return this.getWeekCodes();
    }

    return this.getWeekendCodes();
  }

  private mapCode({ code }: ActCode): [string, string] {
    let codeTranslation;

    for (const [weekCode, weekendCode] of WEEK_AND_WEEKEND_ACT_CODES_MAPPING) {
      if (code === weekCode) codeTranslation = weekendCode;
      if (code === weekendCode) codeTranslation = weekCode;
    }

    return [code, codeTranslation];
  }
}

export default WeekAndWeekendActCodesMappingService;
