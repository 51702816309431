import * as queryString from 'qs';
import API_BASE_URL from '../../../shared/server/apiConfig';
import { perror } from '../../../shared/utils/plog';
import request, { downloadFile } from './apiUtils';
import RALPH_API_BASE_URL from './ralphApi';

export async function fetchAvailableStatements(filters) {
  const filtersString = filters ? queryString.stringify(filters, { skipNulls: true, indices: false }) : '';
  const SEARCH_URL = `${API_BASE_URL}/reports/available-statements?${filtersString}`;
  return request(SEARCH_URL, { method: 'GET' })
    .then((result) => result.json())
    .catch((error) => {
      perror(error);
      return error;
    });
}

export async function fetchAdminStatements(filters) {
  const queryParams = queryString.stringify(filters, { skipNulls: true, indices: false });
  const SEARCH_URL = `${API_BASE_URL}/reports/statement/admin?${queryParams}`;
  return request(SEARCH_URL, { method: 'GET' })
    .then((result) => result.json())
    .catch((error) => {
      perror(error);
      return error;
    });
}

export async function fetchStatementsForDoctor(filters) {
  const queryParams = queryString.stringify(filters, { skipNulls: true, indices: false });
  const SEARCH_URL = `${API_BASE_URL}/reports/statement/doctor?${queryParams}`;
  return request(SEARCH_URL, { method: 'GET' })
    .then((result) => result.json())
    .catch((error) => {
      perror(error);
      return error;
    });
}

export async function fetchStatementsForPool(filters) {
  const queryParams = queryString.stringify(filters, { skipNulls: true, indices: false });
  const SEARCH_URL = `${API_BASE_URL}/reports/statement/pool?${queryParams}`;
  return request(SEARCH_URL, { method: 'GET' })
    .then((result) => result.json())
    .catch((error) => {
      perror(error);
      return error;
    });
}

export async function fetchPaymentRequestForDoctor(filters) {
  const filtersString = queryString.stringify(filters, { skipNulls: true, indices: false });
  const SEARCH_URL = `${API_BASE_URL}/reports/payment-request/doctor?${filtersString}`;
  return request(SEARCH_URL, { method: 'GET' })
    .then((result) => result.json())
    .catch((error) => {
      perror(error);
      return error;
    });
}

export async function fetchPaymentRequestForPool(groupId, periodStart) {
  const filtersString = queryString.stringify({ periodStart, mode: 'json' }, { skipNulls: true, indices: false });
  const SEARCH_URL = `${RALPH_API_BASE_URL}/activities/groups/${groupId}/payment-requests-by-service-date?${filtersString}`;
  return request(SEARCH_URL, { method: 'GET' })
    .then((result) => result.json())
    .catch((error) => {
      perror(error);
      return error;
    });
}

export async function generatePeriodReportLinks(params: {
  userId: string;
  statementDate: number;
  periodStart: number;
  periodEnd: number;
}) {
  const PAYMENT_REQUEST_BY_SENT_DATE_URL = `${RALPH_API_BASE_URL}/payment-requests-by-sent-date?${queryString.stringify(
    {
      userId: params.userId,
      periodStart: params.periodStart,
      periodEnd: params.periodEnd,
      mode: 'share'
    },
    { skipNulls: true, indices: false }
  )}`;
  const STATEMENT_URL = `${RALPH_API_BASE_URL}/statement?${queryString.stringify(
    {
      userId: params.userId,
      statementDate: params.statementDate,
      restrict: 'personal',
      mode: 'share'
    },
    { skipNulls: true, indices: false }
  )}`;

  return Promise.all([
    request(PAYMENT_REQUEST_BY_SENT_DATE_URL, {
      method: 'GET'
    })
      .then((result) => result.json())
      .catch((error) => {
        perror(error);
        return error;
      }),
    request(STATEMENT_URL, {
      method: 'GET'
    })
      .then((result) => result.json())
      .catch((error) => {
        perror(error);
        return error;
      })
  ]);
}

export default class ReportAPI {
  static async downloadDoctorOutsideRAMQReport({
    groupId = undefined,
    userId,
    year,
    mode = 'pdf'
  }: {
    groupId?: string;
    userId: string;
    year: string;
    mode?: 'pdf' | 'html';
  }) {
    const params = { userId, year, mode };

    if (groupId) {
      Object.assign(params, { groupId });
    }

    const PAYMENT_REQUEST_BY_CATEGORY_URL = `${RALPH_API_BASE_URL}/payment-requests-outside-ramq?${queryString.stringify(
      params
    )}`;
    const response = await request(PAYMENT_REQUEST_BY_CATEGORY_URL, { method: 'GET' });

    if (mode === 'pdf') {
      return downloadFile(response);
    }

    return response.text();
  }

  static async downloadPoolOutsideRAMQReport({ poolNumber, year }) {
    const queryParams = queryString.stringify({ poolNumber, year });
    const PAYMENT_REQUEST_BY_CATEGORY_URL = `${API_BASE_URL}/reports/outside-ramq-requests?${queryParams}`;
    const response = await request(PAYMENT_REQUEST_BY_CATEGORY_URL, { method: 'GET' });

    return downloadFile(response);
  }

  static async downloadReceivableAccounts({
    groupId,
    userId,
    date,
    mode = 'pdf'
  }: {
    mode?: 'pdf' | 'html';
    groupId?: string;
    userId: string;
    date: number;
  }) {
    const queryParams = queryString.stringify({ mode, userId, date, groupId });
    const uri = `receivable-accounts?${queryParams}`;
    const response = await request(`${RALPH_API_BASE_URL}/${uri}`, { method: 'GET' });

    if (mode === 'pdf') {
      return downloadFile(response);
    }

    return response.text();
  }

  static async downloadActivitiesSearch(filters) {
    const filtersString = queryString.stringify({ ...filters, mode: 'pdf' }, { skipNulls: true, indices: false });
    const SEARCH_URL = `${RALPH_API_BASE_URL}/search/activities?${filtersString}`;
    const response = await request(SEARCH_URL, { method: 'GET' });

    return downloadFile(response);
  }
}
