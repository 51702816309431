import Act, { ActCode } from '../../../../shared/domain/activity/act/model/Act';
import Recommendation, { NO_RECOMMENDATIONS } from '../types';

const MAX_NUMBER_OF_CONSULTATIONS = 1;
const TREATMENT_CODES = ['9257', '9258'];

const addDifferentChronicPainProblemRequiredDetailRecommendation = (
  act: Partial<Act>,
  numberOfChronicPainInitialConsultationActs: number
): Recommendation<ActCode[]>[] => {
  const chronicPainInitialConsultationCodesInAct = act.codes?.filter((code) => TREATMENT_CODES.includes(code.code));

  if (chronicPainInitialConsultationCodesInAct?.length === 0) return NO_RECOMMENDATIONS;
  if (numberOfChronicPainInitialConsultationActs < MAX_NUMBER_OF_CONSULTATIONS) return NO_RECOMMENDATIONS;
  if (act.codes?.some(({ differentChronicPainProblem = false }) => differentChronicPainProblem))
    return NO_RECOMMENDATIONS;

  return [
    {
      message:
        "Un minimum de 24 mois entre deux rencontres avec un même patient est requis pour la facturation, sauf s'il s'agit d'un problème différent. Cliquez pour ajouter l'élément de contexte 'problème différent' si ce dernier s'applique.",
      apply: ({ codes = [] }) => ({
        fieldName: 'codes',
        newValue: codes.map((code) => {
          if (!TREATMENT_CODES.includes(code.code)) return code;

          return {
            ...code,
            differentChronicPainProblem: true
          };
        }),
        oldValue: codes
      })
    }
  ];
};

export default addDifferentChronicPainProblemRequiredDetailRecommendation;
