import get from 'lodash/get';
import Act, { ActCode } from '../../../../shared/domain/activity/act/model/Act';
import ActCodes from '../../../../shared/domain/activity/act/model/ActCodes';
import NAM from '../../../../shared/domain/ramq/NAM';
import RAMQPatient from '../../../../shared/domain/ramq/RAMQPatient';
import isDefined from '../../../../shared/utils/isDefined';
import Recommendation, { NO_RECOMMENDATIONS } from '../types';
import { findCodeDetail } from '../../../../shared/data/actCodesData';
import RAMQCodes from '../../../../shared/ramq/RamqCodes';

export const MINOR_CARE_CODES_WITH_MATCHING_SUPPLEMENT = [
  ['41006', '41048'],
  ['41046', '41047'],
  ['41049', '41050']
];

const addMinorCareSupplementRecommendation = (act: Partial<Act>): Recommendation<ActCode[] | undefined>[] => {
  if (!isDefined(act.nam) || !NAM.isValidForV1(act.nam!)) {
    return NO_RECOMMENDATIONS;
  }

  const recommendations: Recommendation<ActCode[] | undefined>[] = [];

  const nam = NAM.fromV1(act.nam!, get(act, 'patientInformation.shiftNamCentury', false));
  const patient = new RAMQPatient(nam);
  const actCodes = new ActCodes(act.codes);

  MINOR_CARE_CODES_WITH_MATCHING_SUPPLEMENT.forEach(([minorCareCode, matchingSupplementCode]) => {
    const matchingSupplementCodeData = RAMQCodes.get(matchingSupplementCode);
    if (
      patient.isMinorAtDate(act.date!) &&
      actCodes.isContainsSome(minorCareCode) &&
      !actCodes.isContainsSome(matchingSupplementCode) &&
      !!matchingSupplementCodeData &&
      RAMQCodes.isCodeEffectiveOn(matchingSupplementCodeData, act.date)
    ) {
      recommendations.push(addMatchingSupplementRecommendation(matchingSupplementCode));
    }
  });

  return recommendations;
};

function addMatchingSupplementRecommendation(matchingSupplementCode: string) {
  return {
    message: `Ajout du code ${matchingSupplementCode} pour supplément d'âge en salle d’opération`,
    apply: (partialAct) => {
      const oldValue = partialAct.codes;
      const newValue = [...(partialAct.codes || []), findCodeDetail(matchingSupplementCode)] as ActCode[];

      return {
        fieldName: 'codes',
        newValue,
        oldValue
      };
    }
  };
}

export default addMinorCareSupplementRecommendation;
