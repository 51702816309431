import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getAdditionalFieldsForAct } from '../../codeSpecificLogic/ui/codeSpecificUILogic';
import { selectReduxActFormObject } from '../../containers/ActForm/selectors';
import FormRow from '../Form/FormRow/FormRow';
import RequiredDetail from './RequiredDetail';

export function ActRequiredDetails({ act }) {
  const requiredDetails = getAdditionalFieldsForAct(act);
  return requiredDetails.length > 0 ? (
    <div>
      <FormRow>
        {requiredDetails.map((requiredDetail) => (
          <RequiredDetail key={requiredDetail} requiredDetail={requiredDetail} />
        ))}
      </FormRow>
    </div>
  ) : null;
}

ActRequiredDetails.propTypes = {
  act: PropTypes.object.isRequired
};

const mapStateToProps = createStructuredSelector({
  act: selectReduxActFormObject
});

export default compose(connect(mapStateToProps))(ActRequiredDetails);
