import { get } from 'lodash';
import { AM_PERDIEM, PM_PERDIEM } from '../../../../shared/periods/common/constants';
import MultiSelectCheckboxGroup from '../../../components/Form/Checkbox/MultiSelectCheckboxGroup/MultiSelectCheckboxGroup';

export const AM_PM_LIST = [
  { value: AM_PERDIEM, label: AM_PERDIEM },
  { value: PM_PERDIEM, label: PM_PERDIEM }
];

const summaryRender = (code) => [{ name: 'perdiem', value: get(code, 'amPm', '-').toString() }];

const associatedFields = [
  {
    getName: (index) => `codes[${index}].amPm`,
    notRequired: true,
    type: MultiSelectCheckboxGroup,
    list: AM_PM_LIST
  }
];

const amPm = { associatedFields, summaryRender };

export default amPm;
