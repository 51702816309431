import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import withStyles from '@material-ui/core/styles/withStyles';

import PrintIcon from '@material-ui/icons/Print';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import HybridPrintButton from '../../../components/button/PrintButtons/HybridPrintButton/HybridPrintButton';
import PrintActComponent from './PrintActComponent/PrintActComponent';
import RamqExchangeSummary from './PrintActComponent/RamqExchangeSummary';
import SimpleDate from './PrintActComponent/SimpleDate';

export const styles = (theme) => ({
  smallActCard: {
    width: '100%',
    zoom: '50%',
    pageBreakInside: 'avoid',
    paddingTop: theme.spacing(4)
  },
  actDetail: {
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4)
  }
});

export class PrintAct extends Component {
  assignComponentRef = (el) => {
    this.componentRef = el;
  };

  render() {
    const { acts, title, classes } = this.props;
    return (
      <>
        <HybridPrintButton
          ButtonComponent={() => (
            <Button variant="contained">
              <PrintIcon />
            </Button>
          )}
          componentToPrintRef={() => this.componentRef}
          title={title}
        />
        <Hidden implementation="css" xsUp>
          <div ref={this.assignComponentRef}>
            {acts.map((act) => (
              <div key={act.id} className={classes.smallActCard}>
                <Grid container>
                  <Grid item sm={6} md={6} className={classes.actDetail}>
                    <SimpleDate act={act} />
                    <PrintActComponent act={act} />
                  </Grid>
                  <Grid item sm={6} md={6}>
                    <RamqExchangeSummary act={act} />
                  </Grid>
                </Grid>
              </div>
            ))}
          </div>
        </Hidden>
      </>
    );
  }
}

PrintAct.propTypes = {
  acts: PropTypes.array.isRequired,
  title: PropTypes.string
};

PrintAct.defaultProps = {
  title: ''
};

export default withStyles(styles)(PrintAct);
