import moment from 'moment-timezone';
import Act, { isAct } from 'shared/domain/activity/act/model/Act';
import { URGENT } from 'shared/ramq/contextElements/globalContextElements';
import Recommendation, { NO_RECOMMENDATIONS } from 'app/act/recommendations/types';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import { selectActivitiesInContext } from 'app/containers/Activities/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import Activity from 'shared/domain/activity/global/model/Activity';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';
import { isActBetween0And7 } from 'shared/act/domain/utils';
import { selectSpecialty } from 'app/containers/User/selectors';
import SpecialtyName from 'shared/core/doctor/domain/SpecialtyName';
import codesThatCannotBeBillWithEmergencyContextElement from 'shared/domain/activity/act/act-codes-that-cannot-be-billed-with-emergency-context-element';

export default (partialAct: Partial<Act>): Array<Recommendation<string[]>> => {
  if (!shouldRecommendEmergencyContextElement(partialAct)) {
    return NO_RECOMMENDATIONS;
  }

  return [
    {
      apply: ({ contextElements = [] }) => ({
        fieldName: 'contextElements',
        newValue: [...(contextElements ?? []), URGENT],
        oldValue: contextElements
      }),
      message:
        'Acte facturé en période hors semaine, S.V.P. cliquez sur le crochet pour ajouter URG si support en urgence'
    }
  ];
};

const shouldRecommendEmergencyContextElement = (partialAct: Partial<Act>): boolean => {
  const { codes = [], contextElements = [], place, start, type } = partialAct;

  const userSpecialty = selectSpecialty()(getStore().getState());

  if ([SpecialtyName.ANESTHESIOLOGIST, SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST].includes(userSpecialty)) {
    return false;
  }

  if (type !== ActivityType.ACT) return false;

  if (codes.length === 0) return false;

  if (codes.some(({ code }) => codesThatCannotBeBillWithEmergencyContextElement.includes(code))) return false;

  if (contextElements.includes(URGENT)) return false;

  if (!place) return false;

  if (!start) return false;

  if (isActMadeDuringNormalWorkingDay(partialAct)) return false;

  return true;
};

const isActMadeDuringNormalWorkingDay = (partialAct: Partial<Act>): boolean => {
  const actDate = moment(partialAct.date);

  const SUNDAY_WEEKDAY = 0;
  const SATURDAY_WEEKDAY = 6;

  if (!partialAct.place) return false;

  const { billByAct = false, daysOff = [] } = partialAct.place;

  const isCurrentDayOff =
    [SUNDAY_WEEKDAY, SATURDAY_WEEKDAY].includes(actDate.weekday()) || daysOff.includes(actDate.format('YYYY-MM-DD'));
  const isCurrentDayASupportForAnIntensiveCareUnit = isActMadeDuringSupportForAnIntensiveCareUnit(partialAct);

  if (isCurrentDayOff) return isCurrentDayASupportForAnIntensiveCareUnit;

  const isActConsideredBilledByAct = billByAct || isCurrentDayASupportForAnIntensiveCareUnit;
  const normalDayStartsAt = actDate.clone().hour(7).startOf('hour');
  const normalDayEndsAt = actDate
    .clone()
    .hour(isActConsideredBilledByAct ? 18 : 20)
    .endOf('hour');
  const actStartTime = moment(partialAct.start);
  const noGranularity = undefined;
  const inclusive = '[]';

  return actStartTime.isBetween(normalDayStartsAt, normalDayEndsAt, noGranularity, inclusive);
};

const isActMadeDuringSupportForAnIntensiveCareUnit = (partialAct: Partial<Act>): boolean =>
  sameDayActiveActs(partialAct).some(({ codes = [] }) => codes.some((code) => code.code === '9295'));

const sameDayActiveActs = (partialAct: Partial<Act>): Act[] => {
  const activitiesInContext = selectActivitiesInContext()(getStore().getState()) || [];

  return activitiesInContext.filter((activityInContext: Activity) => {
    if (!isAct(activityInContext)) return false;

    if (activityInContext.status === ActivityStatus.CANCELED) return false;

    if (isActBetween0And7({ date: partialAct.date, start: partialAct.start })) {
      const partialActDate = moment(partialAct.date);
      const lowerBound = partialActDate.clone().subtract(1, 'day').hours(7);
      const upperBound = partialActDate.clone().hours(7);
      const granularity = undefined;

      return moment(activityInContext.start).isBetween(lowerBound, upperBound, granularity, '[)');
    }

    return activityInContext.date === partialAct.date;
  });
};
