import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import { WithStyles } from '@material-ui/core';
import HeaderPortal from '../../components/Portals/HeaderPortal/HeaderPortal';
import {
  PERIOD_REPORTS_ROUTE,
  PAYMENT_REQUEST_ROUTE,
  RECEIVABLE_ACCOUNTS_ROUTE,
  STATEMENT_INCOME_ROUTE,
  STATEMENT_ROUTE,
  OUTSIDE_RAMQ
} from '../Navigation/constants';
import IncomeStatement from './IncomeStatement/IncomeStatement';
import PaymentRequest from './PaymentRequest/PaymentRequest';
import Statement from './Statement/Statement';
import ReportHeader from './ReportHeader/ReportHeader';
import PeriodReportsPage from './PeriodReports/PeriodReportsPage';
import Permission from '../../../shared/domain/authorization/model/Permission';
import OutsideRAMQPage from './OutsideRAMQ/OutsideRAMQPage';
import ReceivableAccountsPage from './ReceivableAccounts/ReceivableAccountsPage/ReceivableAccountsPage';

const REPORT_SECTIONS = [
  {
    name: 'Suivi',
    reports: [
      {
        path: 'statement',
        name: 'État de compte'
      },
      {
        path: 'payment-request',
        name: 'DP Facturées'
      },
      {
        name: 'Rapports de période',
        permissions: [Permission.REPORTS_SEND_BY_MAIL],
        path: 'period-reports'
      }
    ]
  },
  {
    name: "Fin d'année",
    reports: [
      {
        path: 'income-statement',
        name: 'État des revenus'
      },
      {
        path: 'receivable-accounts',
        name: 'Comptes à Recevoir RAMQ'
      }
    ]
  },
  {
    name: 'Autres',
    reports: [
      {
        path: 'outside-ramq',
        name: 'Hors RAMQ'
      }
    ]
  }
];

export const styles = () =>
  ({
    reportsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      flex: 1,
      alignItems: 'center'
    }
  }) as const;

interface Props extends WithStyles<typeof styles> {}

const ReportsRoute = ({ classes }: Props) => {
  const navigate = useNavigate();

  return (
    <div className={classes.reportsContainer}>
      <HeaderPortal>
        <ReportHeader onNavigateToReport={navigate} sections={REPORT_SECTIONS} />
      </HeaderPortal>

      <Routes>
        <Route path={STATEMENT_ROUTE} element={<Statement />} />
        <Route path={STATEMENT_INCOME_ROUTE} element={<IncomeStatement />} />
        <Route path={PAYMENT_REQUEST_ROUTE} element={<PaymentRequest />} />
        <Route path={RECEIVABLE_ACCOUNTS_ROUTE} element={<ReceivableAccountsPage />} />
        <Route path={PERIOD_REPORTS_ROUTE} element={<PeriodReportsPage />} />
        <Route path={OUTSIDE_RAMQ} element={<OutsideRAMQPage />} />
        <Route index element={<Navigate to={STATEMENT_ROUTE} />} />
      </Routes>
    </div>
  );
};

export default withStyles(styles)(ReportsRoute);
