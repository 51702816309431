import moment from 'moment-timezone';
import { findCodeDetail } from '../../../../shared/data/actCodesData';
import Act, { ActCode } from '../../../../shared/domain/activity/act/model/Act';
import Recommendation, { NO_RECOMMENDATIONS } from '../types';

type CodePair = [string, string];

export const AM_PM_CODES: CodePair[] = [
  ['15485', '15600'],
  ['15486', '15601'],
  ['15487', '15602']
];

const addHalfDayPreoperativeClinicRecommendation = (act: Partial<Act>): Recommendation<ActCode[] | undefined>[] => {
  if (!act.start || !act.codes) {
    return NO_RECOMMENDATIONS;
  }

  const startTime = moment(act.start);

  if (isTimeMorning(startTime)) {
    return AM_PM_CODES.filter(([, pmCode]) => act.codes?.some((actCode) => actCode.code === pmCode)).map(
      ([validCode, invalidCode]) => ({
        message: `Modification du code ${invalidCode} par ${validCode} en avant-midi`,
        apply: (partialAct) => ({
          fieldName: 'codes',
          newValue: replaceCode(partialAct?.codes || [], invalidCode, validCode),
          oldValue: partialAct.codes
        })
      })
    );
  }

  if (isTimeAfternoon(startTime)) {
    return AM_PM_CODES.filter(([amCode]) => act.codes?.some((actCode) => actCode.code === amCode)).map(
      ([invalidCode, validCode]) => ({
        message: `Modification du code ${invalidCode} par ${validCode} en après-midi`,
        apply: (partialAct) => ({
          fieldName: 'codes',
          newValue: replaceCode(partialAct?.codes || [], invalidCode, validCode),
          oldValue: partialAct.codes
        })
      })
    );
  }

  return NO_RECOMMENDATIONS;
};

function isTimeMorning(time: moment.Moment) {
  const lowerLimit = time.clone().set({ hour: 7, minute: 0, second: 0, millisecond: 0 });
  const upperLimit = time.clone().set({ hour: 12, minute: 0, second: 0, millisecond: 0 });

  return time.isBetween(lowerLimit, upperLimit, undefined, '[)');
}

function isTimeAfternoon(time: moment.Moment) {
  const lowerLimit = time.clone().set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
  const upperLimit = time.clone().set({ hour: 16, minute: 0, second: 0, millisecond: 0 });

  return time.isBetween(lowerLimit, upperLimit, undefined, '[)');
}

function replaceCode(actCodes: ActCode[], codeToReplace: string, replacementCode: string) {
  return [...actCodes.filter((actCode) => actCode.code !== codeToReplace), findCodeDetail(replacementCode) as ActCode];
}

export default addHalfDayPreoperativeClinicRecommendation;
