import SpecialtyName from 'shared/core/doctor/domain/SpecialtyName';
import Act, { ActCode } from '../../../../shared/domain/activity/act/model/Act';
import Recommendation, { NO_RECOMMENDATIONS } from '../types';
import { selectSpecialty } from '../../../containers/User/selectors';
import { getStore } from '../../../reduxStore/configureStore';

export const SYNTHETIC_PLATE_SUPPLEMENT_CODE = '5472';
export const CODES_THAT_ALLOW_SYNTHETIC_PLATE_SUPPLEMENT = ['5054', '5469', '5475', '5549'];

const addSyntheticPlateSupplement = (act: Partial<Act>): Recommendation<ActCode[] | undefined>[] => {
  if (!act.codes) {
    return NO_RECOMMENDATIONS;
  }
  const userSpecialty = selectSpecialty()(getStore().getState());

  if (
    !isCodeThatAllowSyntheticPlateSupplementPresent(act.codes) ||
    isSyntheticPlateSupplementCodePresent(act.codes) ||
    userSpecialty !== SpecialtyName.GENERAL_SURGEON
  ) {
    return NO_RECOMMENDATIONS;
  }
  return addSyntheticPlateSupplementCodeToAct();
};

export function isSyntheticPlateSupplementCodePresent(actCodes?: ActCode[]): boolean | undefined {
  return actCodes?.some((actCode) => actCode.code === SYNTHETIC_PLATE_SUPPLEMENT_CODE);
}

export function isCodeThatAllowSyntheticPlateSupplementPresent(actCodes?: ActCode[]): boolean | undefined {
  return actCodes?.some((actCode) => CODES_THAT_ALLOW_SYNTHETIC_PLATE_SUPPLEMENT.includes(actCode.code));
}

function addSyntheticPlateSupplementCodeToAct(): Recommendation<ActCode[] | undefined>[] {
  return [
    {
      message: `Ajout du code ${SYNTHETIC_PLATE_SUPPLEMENT_CODE} pour supplément de plaque synthétique`,
      apply: (partialAct) => {
        const oldValue = partialAct.codes;
        const newValue = [...(partialAct.codes || []), { code: SYNTHETIC_PLATE_SUPPLEMENT_CODE }];
        return {
          fieldName: 'codes',
          newValue,
          oldValue
        };
      }
    }
  ];
}

export default addSyntheticPlateSupplement;
