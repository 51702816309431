import { SHIFT_NAM_CENTURY } from '../../../../shared/ramq/requiredDetails/nam';
import { actFormFields } from '../../../containers/ActForm/constants';
import { SwitchField } from '../../../components/Form/Fields';

const summaryRender = () => [];

const associatedFields = [
  {
    getName: () => `${actFormFields.patientInformation}.${SHIFT_NAM_CENTURY}`,
    type: SwitchField,
    label: 'Patient de 100 ans ou plus'
  }
];

const shiftNamCentury = { associatedFields, summaryRender };

export default shiftNamCentury;
